import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import moment from "moment";

const MassageMoreInfo =
  "This therapeutic massage session will be customized to your needs and preferences. A variety of modalities, techniques and tools including " +
  "Swedish, Deep Tissue, Ashiatsu, Stones and Cupping may be in incorporated into the session.";
// const CouplesMoreInfo =
//   "These are two consecutive Customized Therapeutic Massage Sessions of one hour each. A variety of modalities, techniques and tools including Swedish, " +
//   "Deep Tissue, Ashiatsu, Stones and Cupping may be incorporated into these sessions. One of the couples will receive a massage while the other couple waits in the " +
//   "Relaxation Area, then we switch allowing the one that was relaxing to receive a massage and the one that received will wait in the Relaxation Area. Please note, " +
//   "there is a private bathroom for changing but the Relaxation Area is inside the treatment room.";
// const YogassageMoreInfo = `A private session of a combinations of specific Yoga Instruction and Thai Massage. Designed to improve overall health and well being but also 100% customized to your unique
//                    being by considering your physical, energetic, emotional, mental and spiritual constitution and address specific health conditions. The session may include yogic tools like
//                    asanas, pranayama and meditation and Thai (clothed massage) which consist in a relaxing passive yoga stretching.`;
const ThaiMoreInfo = `This full body treatment is offered while fully clothed. I use my hands, forearms, elbows, knees, feet and up to my full body weight to provide this service by positioning my 
                      client into passive and assisted yoga stretches and applying rhythmic compression and other techniques that releases tension from deep connective tissues, like fascia, ligaments, 
                      joints, and bones. This service is recommended for someone needing to improve overall flexibility and stretch or relieve tightness in commonly used muscle groups. NOTE: Please make 
                      sure to wear comfortable stretchy clothes for this session as you'll be fully clothed all the time.`;

// const YogassageTherapyOptions = [
//   {
//     price: "$100.00",
//     time: "1 hr",
//     option: "1 Monthly Session Personalized Program",
//   },
//   {
//     price: "$170.00",
//     time: "1 hr",
//     option: "2 Monthly Sessions Personalized Program",
//   },
//   {
//     price: "$185.00",
//     time: "1 hr",
//     option: "3 Monthly Sessions Personalized Program",
//   },
//   {
//     price: "$200.00",
//     time: "1 hr",
//     option: "4 Monthly Sessions Personalized Program",
//   },
//   {
//     price: "$250.00",
//     time: "1 hr",
//     option: "5 Monthly Sessions Personalized Program",
//   },
// ];

export const Appointments = [
  {
    type: "Customized Therapeutic Massage Session",
    price: "$160.00",
    time: "1 hour 45 minutes",
    info: MassageMoreInfo,
  },
  // {
  //   type: "Couples Back to Back",
  //   price: "$200.00",
  //   time: "2 hours",
  //   info: CouplesMoreInfo,
  // },
  // {
  //   type: "Yogassage Therapy",
  //   info: YogassageMoreInfo,
  //   options: YogassageTherapyOptions,
  // },
  {
    type: "Thai Therapeutic Massage",
    price: "$160.00",
    time: "1 hour 45 minutes",
    info: ThaiMoreInfo,
  },
];

export const EnterDetailsStages = {
  UserInfo: 1,
  IntakeForm: 2,
  AreYouSure: 3,
  Terms: 4,
  Summary: 5,
};

const initialState = {
  selectedService: null,
  // yogassageType: YogassageTherapyOptions[0].option,
  selectedDate: null, // stored as time in milliseconds
  selectedTime: null,
  enterDetailsStage: EnterDetailsStages.UserInfo,
  // UserInfo //
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  invalidEmail: false,
  invalidPhone: false,
  /* Intake Form */
  intakeFormExists: null,
  reasons: {
    "General Relaxation": false,
    "Specific Problem": false,
  },
  reasonsExplanation: "",
  medicalConditions: {
    "Health conditions": false,
    Allergies: false,
    Implants: false,
    Injuries: false,
    "None of the above": false,
  },
  medicalExplanation: "",
  activitiesExplanation: "",
  // Terms of Service //
  terms: {
    "I completed this form to the best of my knowledge.": false,
    "I will inform to my massage therapist of any health or medical changes.": false,
    "I understand that there are benefits and risks of massage therapy.": false,
  },
  appointmentValid: null,
  googleEventId: null,
  emailId: null,
  intakeFormId: null,
  clientId: null,
  intakeFormChanged: false,
  /***************/
  moreInfoClicked: Array(Appointments.length).fill(false),
  freeSlots: null,
  minDate: null, // stored as time in milliseconds
  maxDate: null, // stored as time in milliseconds
  deleteAppointmentsRun: false,
  appointmentAdded: false,
};

const bookingSystemSlice = createSlice({
  name: "bookingSystem",
  initialState,
  reducers: {
    setSelectedService: (state, action) => {
      state.selectedService = action.payload;
    },
    setYogassageType: (state, action) => {
      state.yogassageType = action.payload;
    },
    setSelectedDate: (state, action) => {
      // in order to set, use getTime() method of Date object to store in milliseconds
      state.selectedDate = action.payload;
    },
    setSelectedTime: (state, action) => {
      state.selectedTime = action.payload;
    },
    setEnterDetailsStage: (state, action) => {
      state.enterDetailsStage = action.payload;
    },
    // User Info //
    setFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    setLastName: (state, action) => {
      state.lastName = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setPhone: (state, action) => {
      state.phone = action.payload;
    },
    setInvalidEmail: (state, action) => {
      state.invalidEmail = action.payload;
    },
    setInvalidPhone: (state, action) => {
      state.invalidPhone = action.payload;
    },
    /* Intake Form */
    setIntakeFormExists: (state, action) => {
      state.intakeFormExists = action.payload;
    },
    setReasons: (state, action) => {
      state.reasons = action.payload;
    },
    setReasonsExplanation: (state, action) => {
      state.reasonsExplanation = action.payload;
    },
    setMedicalConditions: (state, action) => {
      state.medicalConditions = action.payload;
    },
    setMedicalExplanation: (state, action) => {
      state.medicalExplanation = action.payload;
    },
    setActivitiesExplanation: (state, action) => {
      state.activitiesExplanation = action.payload;
    },
    setTerms: (state, action) => {
      state.terms = action.payload;
    },
    setAppointmentValid: (state, action) => {
      state.appointmentValid = action.payload;
    },
    setGoogleEventId: (state, action) => {
      state.googleEventId = action.payload;
    },
    setEmailId: (state, action) => {
      state.emailId = action.payload;
    },
    setIntakeFormId: (state, action) => {
      state.intakeFormId = action.payload;
    },
    setClientId: (state, action) => {
      state.clientId = action.payload;
    },
    setIntakeFormChanged: (state, action) => {
      state.intakeFormChanged = action.payload;
    },
    /***************/
    setMoreInfoClicked: (state, action) => {
      const { index, value } = action.payload;
      state.moreInfoClicked[index] = value;
    },
    setFreeSlots: (state, action) => {
      state.freeSlots = action.payload;
    },
    setMinDate: (state, action) => {
      // in order to set, use getTime() method of Date object to store in milliseconds
      state.minDate = action.payload;
    },
    setMaxDate: (state, action) => {
      // in order to set, use getTime() method of Date object to store in milliseconds
      state.maxDate = action.payload;
    },
    setDeleteAppointmentsRun: (state, action) => {
      state.deleteAppointmentsRun = action.payload;
    },
    setAppointmentAdded: (state, action) => {
      state.appointmentAdded = action.payload;
    },
    resetState: () => initialState,
  },
});

export const deleteAppointmentsAsync = () => async (dispatch) => {
  try {
    axios
      .delete("/google/deleteAppointments", {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
        },
      })
      .then(() => {
        dispatch(setDeleteAppointmentsRun(true));
      })
      .catch((error) => {
        console.error(error);
      });
  } catch (error) {
    console.error(error);
  }
};

export const getFreeSlotsAsync =
  (selectedService, yogassageType) => async (dispatch) => {
    try {
      const queryParams = new URLSearchParams({ yogassageType });

      const response = await axios.get(`/google/freeSlots/${selectedService}`, {
        params: queryParams,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
        },
      });
      dispatch(setFreeSlots(response.data.freeSlots));
      if (response.data.freeSlots.length === 0) {
        dispatch(setMinDate(null));
        dispatch(setMaxDate(null));
      } else {
        dispatch(
          setMinDate(
            moment(response.data.freeSlots[0].date, "MM-DD-YYYY")
              .toDate()
              .getTime()
          )
        );
        dispatch(
          setMaxDate(
            moment(
              response.data.freeSlots[response.data.freeSlots.length - 1].date,
              "MM-DD-YYYY"
            )
              .toDate()
              .getTime()
          )
        );
        dispatch(
          setSelectedDate(
            moment(response.data.freeSlots[0].date, "MM-DD-YYYY")
              .toDate()
              .getTime()
          )
        );
      }
    } catch (error) {
      console.error(error);
      // Dispatch an error action to update the state with the error message
    }
  };

export const doesClientExistAsync =
  (firstName, lastName, email, phone) => async (dispatch) => {
    console.log("doesClientExistAsync reached!");
    try {
      const queryParams = new URLSearchParams({
        firstName,
        lastName,
        email,
        phone,
      });
      const response = await axios.get("/db/check-client", {
        params: queryParams,
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
        },
      });
      const exists = response.data.exists;
      console.log(`Client exists: ${exists}`);
      dispatch(setIntakeFormExists(exists));
      if (exists) {
        dispatch(getIntakeFormAsync(response.data.intakeFormId));
        dispatch(setIntakeFormId(response.data.intakeFormId));
        dispatch(setClientId(response.data.clientId));
      }
    } catch (error) {
      console.error(error);
    }
  };

export const getIntakeFormAsync = (intakeFormId) => async (dispatch) => {
  console.log("getIntakeForm reached!");
  try {
    const queryParams = new URLSearchParams({ intakeFormId });
    const response = await axios.get("/db/get-intake-form", {
      params: queryParams,
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
      },
    });

    dispatch(setReasons(response.data.reasons));
    dispatch(setReasonsExplanation(response.data.reasonsexplanation));
    dispatch(setMedicalConditions(response.data.medicalconditions));
    dispatch(setMedicalExplanation(response.data.medicalexplanation));
    dispatch(setActivitiesExplanation(response.data.activitiesexplanation));
  } catch (error) {
    console.log(error);
  }
};

export const isFreeSlotValidAsync =
  (selectedService, selectedDate, selectedTime, yogassageType) =>
  async (dispatch) => {
    console.log("isFreeSlotValid reached!");
    try {
      const queryParams = new URLSearchParams({ yogassageType });

      const response = await axios.get(
        `/google/validSlot/${selectedService}/${selectedDate}/${selectedTime}`,
        {
          params: queryParams,
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
          },
        }
      );
      const isAvailable = response.data.isAvailable;
      console.log("Appointment is valid. Book it!");
      dispatch(setAppointmentValid(isAvailable));
    } catch (error) {
      console.error(error);
    }
  };

export const createGoogleEventAsync =
  (
    selectedDate,
    selectedTime,
    selectedService,
    yogassageType,
    firstName,
    lastName,
    email,
    phone
  ) =>
  async (dispatch) => {
    console.log("createGoogleEvent reached!");
    try {
      const eventData = {
        appointmentType: selectedService,
        yogassageType,
        date: moment.unix(selectedDate / 1000).format("YYYY-MM-DD"),
        time: selectedTime,
        name: `${firstName} ${lastName}`,
        email: email,
        phone: phone,
      };
      const response = await axios.post("/google/addEvent", eventData, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
        },
      });
      console.log(response);
      dispatch(setGoogleEventId(response.data.eventId));
    } catch (error) {
      console.log(error);
    }
  };

export const addEmailAsync = (email) => async (dispatch) => {
  console.log("addEmail reached!");
  try {
    const emailData = {
      email,
    };
    const response = await axios.post("/db/add-email", emailData, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
      },
    });
    dispatch(setEmailId(response.data.emailId));
  } catch (error) {
    console.log(error);
  }
};

export const addIntakeFormAsync =
  (
    reasons,
    reasonsExplanation,
    medicalConditions,
    medicalExplanation,
    activitiesExplanation
  ) =>
  async (dispatch) => {
    console.log("addIntakeForm reached!");
    try {
      const intakeFormData = {
        reasons,
        reasonsExplanation,
        medicalConditions,
        medicalExplanation,
        activitiesExplanation,
      };
      const response = await axios.post("/db/add-intake-form", intakeFormData, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
        },
      });
      dispatch(setIntakeFormId(response.data.intakeFormId));
    } catch (error) {
      console.log(error);
    }
  };

export const updateIntakeFormAsync =
  (
    intakeFormId,
    reasons,
    reasonsExplanation,
    medicalConditions,
    medicalExplanation,
    activitiesExplanation
  ) =>
  async (dispatch) => {
    console.log("addIntakeForm reached!");
    try {
      const intakeFormData = {
        intakeFormId,
        reasons,
        reasonsExplanation,
        medicalConditions,
        medicalExplanation,
        activitiesExplanation,
      };
      const response = await axios.post(
        "/db/update-intake-form",
        intakeFormData,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
          },
        }
      );
      console.log(response);

      dispatch(setIntakeFormChanged(false));
    } catch (error) {
      console.log(error);
    }
  };

export const addClientAsync =
  (emailId, intakeFormId, firstName, lastName, phone) => async (dispatch) => {
    console.log("addClient reached!");
    try {
      const clientData = {
        emailId,
        intakeFormId,
        firstName,
        lastName,
        phone,
      };
      const response = await axios.post("/db/add-client", clientData, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
        },
      });
      dispatch(setClientId(response.data.clientId));
    } catch (error) {
      console.log(error);
    }
  };

export const addAppointmentAsync =
  (
    clientId,
    googleEventId,
    selectedTime,
    selectedDate,
    selectedService,
    yogassageType,
    firstName,
    lastName,
    email,
    phone,
    reasons,
    reasonsExplanation,
    medicalConditions,
    medicalExplanation,
    activitiesExplanation
  ) =>
  async (dispatch) => {
    console.log("addAppointment reached!");
    try {
      const appointmentData = {
        clientId,
        googleEventId,
        selectedTime,
        selectedDate,
        selectedService,
        yogassageType,
        firstName,
        lastName,
        email,
        phone,
        reasons,
        reasonsExplanation,
        medicalConditions,
        medicalExplanation,
        activitiesExplanation,
      };

      await axios.post("/db/add-appointment", appointmentData, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
        },
      });

      dispatch(setAppointmentAdded(true));
    } catch (error) {
      console.log(error);
    }
  };

export const {
  setSelectedService,
  setYogassageType,
  setSelectedDate,
  setSelectedTime,
  setEnterDetailsStage,
  setFirstName,
  setLastName,
  setEmail,
  setPhone,
  setInvalidEmail,
  setInvalidPhone,
  setIntakeFormExists,
  setReasons,
  setReasonsExplanation,
  setMedicalConditions,
  setMedicalExplanation,
  setDeleteAppointmentsRun,
  setAppointmentAdded,
  setActivitiesExplanation,
  setMoreInfoClicked,
  setFreeSlots,
  setMinDate,
  setMaxDate,
  setTerms,
  setAppointmentValid,
  setGoogleEventId,
  setEmailId,
  setClientId,
  setIntakeFormId,
  setIntakeFormChanged,
  resetState,
} = bookingSystemSlice.actions;

export default bookingSystemSlice.reducer;
